<template>
    <div id="app_currentrightclick">
      <v-menu
        :elevation="0"
        v-model="shower"
        :position-x="x"
        :position-y="y"
        top
        offset-y
        close-on-content-click
        close-on-click
        transition="slide-y-transition"
      >
        <!-- <template v-slot:activator="{ on }">
            <v-btn class="elevation-0" fab small v-on="on" outlined>
            <v-icon>more_vert</v-icon>
            </v-btn>
        </template> -->
        <v-list>
        <v-list-item >
            <v-list-item-icon>
                <v-icon>visibility</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{$t('currentRClick.preview')}}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-tooltip bottom attach content-class="mt-n14" v-model="showToolTipFileDetail" :disabled="!disableDataStorageOverload">
            <template v-slot:activator="{ on }">
                <v-list-item
                :ripple="!disableDataStorageOverload"
                v-on="on"
                >
                <v-list-item class="pa-0" :disabled="disableDataStorageOverload">
                    <v-list-item-icon>
                    <v-icon>
                        mdi-information
                    </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                    <v-list-item-title>
                        {{$t('myoutboxPage.buttonAction.fileDetail')}}
                    </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                </v-list-item>
            </template>
                <span>{{ $t("myinboxPage.storageisfull") }}</span>
            </v-tooltip>

            <v-tooltip bottom attach content-class="mt-n14" v-model="showToolTipDownloadFile">
            <template v-slot:activator="{ on }">  
           
                <v-list-item
                v-on="on"
                >
                <v-list-item class="pa-0">
                    <v-list-item-icon>
                    <v-icon>
                        mdi-download-multiple
                    </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                    <v-list-item-title>
                        {{$t('myoutboxPage.buttonAction.download')}}
                    </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                </v-list-item>
            </template>
                <span>{{ $t("myinboxPage.storageisfull") }}</span>
            </v-tooltip>
                <!-- ลบลงถังขยะ -->
            <v-list-item
            v-if="status_permission_outbox === 'AD'"
            >
            <v-list-item-icon>
                <v-icon>delete</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t("checkdelete.delete") }}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
            <!-- ยกเลิกคำขอลบ -->
            <v-list-item
            >
            <v-list-item-icon>
                <v-icon>cancel</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>ยกเลิกคำขอลบ</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
            <v-tooltip bottom attach content-class="mt-n14" v-model="showToolTipDeleteForever" :disabled="!disableDataStorageOverload">

                <v-list-item class="pa-0">
                    <v-list-item-icon>
                    <v-icon>delete_forever</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                    <v-list-item-title>{{$t('checkdelete.deleteandrequest')}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-tooltip>
        </v-list>
      </v-menu>
      <!-- snackbardowload -->
      <div class="text-center ma-2">
        <v-snackbar v-model="snackbardowload" bottom color="white" :timeout="timeout">
          <v-list color="white" style="padding-top: 0px;">
            <v-toolbar :color="color.theme" dense short :dark="color.darkTheme" flat fab>
              {{$t('myinboxPage.dowload.dowloading')}}
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="colsedowload()" fab x-small>
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-list-item v-for="(item,i) in newlist" :key="i" dense style="height:40px; width:400px;">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
                <!-- <v-list-item-title > {{namedowload}}</v-list-item-title> -->
              </v-list-item-content>
              <v-progress-circular
                :rotate="90"
                :size="35"
                :width="3"
                :value="item.value"
                color="teal"
                style="margin-left:25px"
              >
                <v-list-item-title v-text="item.value"></v-list-item-title>
              </v-progress-circular>
            </v-list-item>
          </v-list>
        </v-snackbar>
      </div>
      <listshare
        :show="openlistshare"
        :filedata="file"
        :parentfolder="parentfolder"
        @closelistshare="closeDialogEditname(), openlistshare = false"
        @openlistshare="openlistshare = true"
        @closeDrag="removeEvent()"
        @openDrag="addEvent()"
      ></listshare>
      <!-- <previewfile
        @closelistshare="closeDialogEditname(), openlistshare = false"
        @openlistshare="openlistshare = true"
      ></listshare>
      <previewfile
        :show="openpreviewfile"
        :filedata="file"
        :parentfolder="parentfolder"
        @closepreviewfile="openpreviewfile = false"
        @openpreviewfile="openpreviewfile = true"
      ></previewfile>-->
      <newpreviewfile
        :parentfolder="parentfolder"
        :show="opennewpreviewfile"
        :arrayfile="arrayfile"
        :filedata_1="file"
        @closepreviewfile="opennewpreviewfile = false"
        @openpreviewfile="opennewpreviewfile = true"
        @closeDrag="removeEvent()"
        @openDrag="addEvent()"
      ></newpreviewfile>
      <changename
        :show="openchangename"
        :filedata="file"
        :parentfolder="parentfolder"
        @closechangename="closeDialogEditname(), openchangename = false"
        @openchangename="openchangename = true"
        @closeDrag="removeEvent()"
        @openDrag="addEvent()"
      ></changename>
      <relationfile
        :show="openrelationfile"
        :filedata="file"
        :parentfolder="parentfolder"
        @closedrelationfile="openrelationfile = false,$emit('openDrag')"
        @openrelationfile="openrelationfile = true"
        @closeDrag="$emit('closeDrag')"
        @openDrag="$emit('openDrag')"
      ></relationfile>
      <createrelationfile
        :show="opencreaterelationfile"
        @updatedatafile="closeDialogEditname()"
        @closedialog="opencreaterelationfile = false"
        :filedata="file"
        @closeDrag="$emit('closeDrag')"
        @openDrag="$emit('openDrag')"
      ></createrelationfile>
       <dialogsnackbardowload
        :show="opensnackbar"
        @closedialog="opensnackbar = false"
        :filedata="file"
        :percen="newpercen"
        :name="namefile"
        :list="new_list"
        :btsdownload="btsdownload"
      ></dialogsnackbardowload>
      <comfirmdeletesharefile
        :show="opendialogconfirmdelete"
        :filedata="file"
        @closedialog="opendialogconfirmdelete = false"
        @deletefile="fn_cancelsharefile()"
      ></comfirmdeletesharefile>
      <confirmhidedirectoryshare
        :show="opendialogconfirmhidedirectoryfile"
        :filedata="file"
        @closedialog="opendialogconfirmhidedirectoryfile = false"
        @hidefile="fn_hidedirectorysharefile()"
      ></confirmhidedirectoryshare>
      <confirmunhidedirectoryshare
        :show="opendialogconfirmunhidedirectoryfile"
        :filedata="file"
        @closedialog="opendialogconfirmunhidedirectoryfile = false"
        @hidefile="fn_hidedirectorysharefile()"
      ></confirmunhidedirectoryshare>
      <dialogsnackbardowloadfile
        :show="opensnackbardownloadfile"
        @closedialog="opensnackbardownloadfile = false"
        :filedata="file"
        :percen="newpercen"
        :name="namefile"
        :list="new_list"
        :btsdownload="btsdownload"
      ></dialogsnackbardowloadfile>
    </div>
  </template>
  
  <script>
  import download from "downloadjs";
  import { saveAs } from "file-saver";
  import { mapState, mapGetters } from "vuex";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import "sweetalert2/src/sweetalert2.scss";
  import gbfGenerate from "@/globalFunctions/generateAuthorize";
  // import dl_movefile from "../optional/dialog-movefile";
  import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
  
  const dl_movefile = () => import("../optional/dialog-movefile");
  const sendmail = () => import("../optional/dialog-sendmail");
  const sendonechat = () => import("../optional/dialog-sendonechat");
  const changename = () => import("../optional/dialog-changename");
  const newpreviewfile = () => import("../optional/dialog-preview");
  // const previewfile = () => import("../optional/dialog-preview");
  const sharelink = () => import("../optional/dialog-sharelink");
  const listshare = () => import("../optional/dialog-listshare");
  const relationfile = () => import("../optional/dialog-relationmyfile");
  const createrelationfile = () => import("../optional/dialog-createrelation");
  const dialogsnackbardowload = () => import("../optional/dialog-snackbardownload");
  const comfirmdeletesharefile = () => import("../optional/dialog-confirmdeletesharefile");
  const confirmhidedirectoryshare = () => import("../optional/dialog-confirmhidedirectorysharefile");
  const confirmunhidedirectoryshare = () => import("../optional/dialog-comfirmunhidedirectoryshare");
  const dialogsnackbardowloadfile = () => import("../optional/dialog-snackbardownloadfile");
  
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });
  
  export default {
    props: ["show", "AreaX", "AreaY", "file", "parentfolder", "parentfoldername","arrayfile","confirm","typesharefile"],
    components: {
      changename,
      sendmail,
      dl_movefile,
      sendonechat,
      newpreviewfile,
      sharelink,
      sharelink,
      listshare,
      relationfile,
      createrelationfile,
      dialogsnackbardowload,
      comfirmdeletesharefile,
      confirmhidedirectoryshare,
      confirmunhidedirectoryshare,
      dialogsnackbardowloadfile
    },
    data: function () {
      return {
        showToolTipDeleteForever:"",
        showToolTipDownloadFile:'',
        showToolTipFileDetail:"",
        opensnackbardownloadfile:false,
        opendialogconfirmhidedirectoryfile:false,
        opendialogconfirmunhidedirectoryfile:false,
        opendialogconfirmdelete:false,
        new_list:[],
        btsdownload:false,
        percent: 0,
        namefile: "",
        newpercen: [],
        opensnackbar:false,
        listdatadowload: [],
        newlist: [],
        timeout: 60000,
        percentCompleted: 0,
        namedowload: "",
        itemRelationfile: [
          {
            text: "currentRClick.relationfile",
            icon: "device_hub",
            to: "relationfile",
            event: "fn",
            relation: "",
            permission: false,
          },
          //  {
          //   text: "currentRClick.createrelationfile",
          //   icon: "mdi-plus",
          //   to: "createrelationfile",
          //   event: "fn",
          //   relation:true,
          //   permission:true
          // },
          //  {
          //   text: "currentRClick.deleterelationfile",
          //   icon: "mdi-trash-can",
          //   to: "deleterelationfile",
          //   event: "fn",
          //   relation:false,
          //   permission:true
          // },
        ],
        // itemOpenfolder: [
        //   {
        //     text: "currentRClick.open",
        //     icon: "mdi-folder-open",
        //     to: "openfolder",
        //     event: "fn",
        //   },
        // ],
        itemsOpenFile: [
          // {
          //   text: "shareRClick.preview",
          //   icon: "visibility",
          //   to: "preview",
          //   event: "fn"
          // },
          {
            text: "currentRClick.preview",
            icon: "visibility",
            to: "newpreview",
            event: "fn",
          },
        ],
        itemsDownloadFile: [
          {
            text: "shareRClick.downloadfile",
            icon: "mdi-download",
            to: "downloadfile",
            event: "fn",
          },
        ],
        itemhide:[
          {
            text: "shareRClick.hide",
            icon: "mdi-eye-off",
            to: "hide",
            event: "fn",
          },
        ],
        itemunhide:[
          {
            text: "shareRClick.unhide",
            icon: "mdi-eye",
            to: "unhide",
            event: "fn",
          },
        ],
        itemcancelshared:[
         {
            text: "shareRClick.movetotrash",
            icon: "delete",
            to: "trash",
            event: "fn",
            // viewonly01: true,
            // download02:true,
            // upload03:true,
            // business: true,
            // edit04: true,
            // delete05:true
          },
        ],
        items: [
          {
            text: "shareRClick.rename",
            icon: "create",
            to: "editname",
            event: "fn",
            viewonly01: false,
            download02:false,
            upload03:false,
            business: true,
            edit04: true,
            delete05: true
          },
          {
            text: "shareRClick.addtostared",
            icon: "star",
            to: "starfile",
            event: "fn",
            viewonly01: true,
            download02:true,
            upload03:true,
            business: true,
            edit04: true,
            delete05: true
          },
        ],
        opensharelink: false,
        opennewpreviewfile: false,
        openchangename: false,
        opensendmail: false,
        opendlmovefile: false,
        opendialogonechat: false,
        openlistshare: false,
        openrelationfile: false,
        deniedstarred: false,
        snackbardowload: false,
        opencreaterelationfile: false,
        // showMenu: false,
        x: 0,
        y: 0,
      };
    },
    computed: {
      ...mapState([
      "status_permission_outbox",
      "status_permission_inbox",
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
      "storage_usage_biz",
    ]),
      ...mapState({ processloader: "loading" }),
      ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
        "dataCheckPackage",
        "dataStorageMax",
      ]),
      imageHeight() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 220;
          case "sm":
            return 400;
          case "md":
            return 500;
          case "lg":
            return 600;
          case "xl":
            return 800;
        }
      },
      shower: {
        get() {
          if (this.show === true) {
            this.showmenus();
            console.log("+++++++++++arrayfile",this.arrayfile);
          }
          return this.show;
        },
        set(value) {
          if (!value) {
            this.$emit("closecurrentonly");
          }
        },
      },
    //   itemRelationfileFilter() {
    //     if (this.file["status_check"] === "E") {
    //       if (this.file["relation_id"] === "") {
    //         return this.itemRelationfile.filter(
    //           (menu) => menu.relation === true || menu.relation === ""
    //         );
    //       } else {
    //         return this.itemRelationfile.filter(
    //           (menu) => menu.relation === false || menu.relation === ""
    //         );
    //       }
    //     } else {
    //       return this.itemRelationfile.filter(
    //         (menu) => menu.permission === false
    //       );
    //     }
    //   },
      ///////แก้ใหม่ check ตาม permission_account///////
    //   itemsFilter() {
    //     if (this.dataAccountActive.type === "Business") {
    //       console.log("this.file+++++++", this.file);
    //       if ("file_owner_eng" in this.file) {        
    //           if (this.file["file_type"] !== "folder") {
    //             if(this.file["permission_account"] === '01'){
    //                return this.items.filter((menu) => menu.viewonly01 === true);
    //             }else if(this.file["permission_account"] === '02'){
    //               return this.items.filter((menu) => menu.download02 === true);
    //             }else if(this.file["permission_account"] === '03'){
    //               return this.items.filter((menu) => menu.upload03 === true);
    //             }else if(this.file["permission_account"] === '04'){
    //               return this.items.filter((menu) => menu.edit04 === true);
    //             }else if(this.file["permission_account"] === '05'){
    //               return this.items.filter((menu) => menu.delete05 === true);
    //             }else{
    //               return this.items.filter((menu) => menu.viewonly01 === true);
    //             }
    //           //   if (this.file["permission_account"].view_only === "True" && this.file["permission_account"].edit === "True" && this.file["permission_account"].delete === "True") {
  
    //           //     return this.items.filter((menu) => menu.edit === true && menu.delete === true);
  
    //           //   } else if (this.file["permission_account"].view_only === "True" && this.file["permission_account"].edit === "False" && this.file["permission_account"].delete === "True") {
                  
    //           //     return this.items.filter((menu) => menu.viewonly === true && menu.delete === true);
  
    //           //   }else if (this.file["permission_account"].view_only === "True" && this.file["permission_account"].edit === "True" && this.file["permission_account"].delete === "False") {
                  
    //           //     return this.items.filter((menu) => menu.edit === true );
  
    //           //   }else if (this.file["permission_account"].view_only === "True" && this.file["permission_account"].edit === "False" && this.file["permission_account"].delete === "False") {
                  
    //           //     return this.items.filter((menu) => menu.viewonly === true );
    //           //   }
    //           } else {
    //             if(this.file["permission_account"] === '01'){
    //                return this.items.filter((menu) => menu.viewonly01 === true);
    //             }else if(this.file["permission_account"] === '02'){
    //               return this.items.filter((menu) => menu.download02 === true);
    //               }else if(this.file["permission_account"] === '03'){
    //               return this.items.filter((menu) => menu.upload03 === true);
    //             }else if(this.file["permission_account"] === '04'){
    //               return this.items.filter((menu) => menu.edit04 === true);
    //             }else if(this.file["permission_account"] === '05'){
    //               return this.items.filter((menu) => menu.delete05 === true);
    //             }else{
    //               return this.items.filter((menu) => menu.viewonly01 === true);
    //             }
    //           //   if ( this.file["permission_account"].view_only === "True" && this.file["permission_account"].edit === "True" && this.file["permission_account"].delete === "True") {
                  
    //           //     return this.items.filter((menu) => menu.edit === true && menu.delete === true);
  
    //           //   } else if ( this.file["permission_account"].view_only === "True" && this.file["permission_account"].edit === "False" && this.file["permission_account"].delete === "True") {
                  
    //           //     return this.items.filter((menu) => menu.viewonly === true && menu.delete === true);
  
    //           //   }else if (this.file["permission_account"].view_only === "True" && this.file["permission_account"].edit === "True" && this.file["permission_account"].delete === "False") {
                  
    //           //     return this.items.filter((menu) => menu.edit === true );
  
    //           //   }else if (this.file["permission_account"].view_only === "True" && this.file["permission_account"].edit === "False" && this.file["permission_account"].delete === "False") {
                  
    //           //     return this.items.filter((menu) => menu.viewonly === true );
    //           //   }
    //           }
    //       } else {
    //         return this.items;
    //       }
    //     }else{
    //       console.log("permissioncitizenedit",this.file);
    //        if(this.file["permission_account"] === '01'){
    //           return this.items.filter((menu) => menu.viewonly01 === true);
    //         }else if(this.file["permission_account"] === '02'){
    //           return this.items.filter((menu) => menu.download02 === true);
    //           }else if(this.file["permission_account"] === '03'){
    //           return this.items.filter((menu) => menu.upload03 === true);
    //         }else if(this.file["permission_account"] === '04'){
    //           return this.items.filter((menu) => menu.edit04 === true);
    //         }else if(this.file["permission_account"] === '05'){
    //           return this.items.filter((menu) => menu.delete05 === true);
    //         }else{
    //           return this.items.filter((menu) => menu.viewonly01 === true);
    //         }
    //       // if(this.dataAccountActive.type === "Citizen" && this.file["permission_account"].view_only === "True" && this.file["permission_account"].edit === "True" && this.file["permission_account"].delete === "True" ){
  
    //       //   return this.items.filter((menu) => menu.edit === true && menu.delete === true);
  
    //       // }else if(this.dataAccountActive.type === "Citizen" && this.file["permission_account"].view_only === "True" && this.file["permission_account"].edit === "False" && this.file["permission_account"].delete === "True" ){
            
    //       //   return this.items.filter((menu) => menu.viewonly === true && menu.delete === true);
  
    //       // }else if(this.dataAccountActive.type === "Citizen" && this.file["permission_account"].view_only === "True" && this.file["permission_account"].edit === "True" && this.file["permission_account"].delete === "False" ){
            
    //       //   return this.items.filter((menu) => menu.edit === true);
    //       // }
    //       // else if(this.dataAccountActive.type === "Citizen" && this.file["permission_account"].view_only === "True" && this.file["permission_account"].edit === "False" && this.file["permission_account"].delete === "False" ){
            
    //       //   return this.items.filter((menu) => menu.viewonly === true);
    //       // }
    //     }
    //     // เช็ค เมนูคลิกขวาอันเก่า
    //     // }else if(this.dataAccountActive.type === "Citizen" && this.file["file_permission"] === 'E') {
    //     //   return this.items.filter(menu => menu.business === true ); ;
  
    //     // } else if (this.dataAccountActive.type === "Citizen" && this.file["file_permission"] === 'V'){
    //     //   return this.items.filter(menu => menu.viewonly === true && menu.business === true); 
    //     // }
        
    //   },
      disableByPackage() {      
        // return this.dataCheckPackage.type === 'Business'? this.dataCheckPackage.package ? false : true : this.dataCheckPackage.package ? false : true
        return false
      },
      disableDataStorageOverload() {
      return this.convertstorageToByte(this.storage_usage_biz) > this.convertstorageToByte(this.dataStorageMax);
    },
    },
    watch:{
      confirm(val){
        console.log("val333******",val);
        if(val === false){
          console.log("val******",val);
          this.opendialogconfirmdelete = false;
        }
      }
    },
    methods: {
    convertstorageToByte(_storage) {
      console.log(_storage);
      let splitStorage = _storage.split(" ");
      let databyte = 0;

      if (splitStorage[1] === "KB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1024 : parseFloat(splitStorage[0]) * 1024;
      } else if (splitStorage[1] === "MB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1048576 : parseFloat(splitStorage[0]) * 1048576;
      } else if (splitStorage[1] === "GB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1073741824 : parseFloat(splitStorage[0]) * 1073741824;
      } else if (splitStorage[1] === "TB") {
        databyte =
          typeof splitStorage[0] === "number" ? splitStorage[0] * 1099511627776 : parseFloat(splitStorage[0]) * 1099511627776;
      } else {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] : parseFloat(splitStorage[0]);
      }
      return Math.ceil(databyte);
    },
     async fn_hidedirectorysharefile(){
        // this.$emit("callupdatestatusfile", this.file, "T");
        // this.opendialogconfirmhidedirectoryfile = false;
        console.log("fileeeeeeee",this.file);
        let api;
        let payload;
        let msgres;
       
          if (this.file.file_type === "folder") {
            payload = {
              folder_id: this.file.file_id,
                  folder_name: "",
                  status_folder: this.typesharefile === 'ไฟล์แชร์ร่วมกัน' ? "T" : "N",
                  move_to_id: "",
                  account_id: this.file.account_sender,
            };
  
            api = "/api/v2/update_folder_id";
          } else {
            payload = {
              account_id: this.file.account_sender,
              file_id: this.file.file_id,
              filename: "",
              folder_id: "",
              status_file: this.typesharefile === 'ไฟล์แชร์ร่วมกัน' ? "T" : "N",
            };
            api = "/api/v2/update_file_id";
          }
          let auth = await gbfGenerate.generateToken();
          console.log("payload", payload);
          this.axios
            .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + api, payload,{headers: { Authorization: auth.code },})
            .then(response => {
              console.log(response);
              if (response.data.status === "OK") {
                if (this.typesharefile === "ไฟล์แชร์ร่วมกัน") {
                  msgres = this.$t("toast.text.hide") + this.file.file_name +  this.$t("toast.text.success");
                }else{
                  msgres = this.$t("toast.text.unhide") + this.file.file_name +  this.$t("toast.text.success");
                }
           Toast.fire({
                  icon: "success",
                  title: msgres
                });
                if(this.typesharefile === 'ไฟล์แชร์ร่วมกัน' ){
                  this.opendialogconfirmhidedirectoryfile = false;
                }else{
                  this.opendialogconfirmunhidedirectoryfile = false;
                }
                // this.loadsharefolder();
                this.$emit('reload')
              } else {
                Toast.fire({
                  icon: "error",
                  title: response.data.errorMessage
                });
              }
            })
            .catch(error => {
              console.log(error);
              Toast.fire({
                icon: "error",
                title:this.$t("toast.text.cannotstar") + this.file.file_name + this.$t("toast.text.textth")
              });
            });
      },
   async fn_cancelsharefile(){
        // this.$emit("callupdatestatusfile", this.file, "T");
        // this.opendialogconfirmdelete = false;
        console.log("this.file",this.file);
        let payload = {
          account_id: this.dataAccountId,
          account_id_reciever: this.file.account_reciever,
          business_id_reciever:this.file.business_id_reciever,
          id:this.file.file_id
        }
        console.log("payload",payload);
        let auth = await gbfGenerate.generateToken();
        await this.axios
            .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/cancel_shared", payload,{headers: { Authorization: auth.code },})
            .then(response => {
              console.log(response);
              if (response.data.status === "OK") {
                Toast.fire({
                  icon: "success",
                  title: this.$t("toast.text.cancelshared") + this.file.file_name +  this.$t("toast.text.success"),
                });
                this.opendialogconfirmdelete = false;
                this.$emit('reload');
              }else{
                   Toast.fire({
                  icon: "erroe",
                  title: response.data.errorMessage,
                });
              }
            })
            .catch(error => {
              Toast.fire({
                icon: "error",
                title: this.$t("myinboxPage.msg.apierror")
              });
              console.log(error);
            });
      },
      showmenus() {
        this.showMenu = false;
        this.x = this.AreaX;
        this.y = this.AreaY;
        this.$nextTick(() => {
          this.showMenu = true;
        });
      },
      checkBusinessDrive(parameter) {
        if (parameter === "M") {
          return true;
        } else if (
          this.dataAccountActive.type === "Business" &&
          this.file["file_type"] === "folder"
        ) {
          if ("file_owner_eng" in this.file) {
            return false;
          } else {
            return true;
          }
        } else {
          false;
        }
      },
      clicker(parameter, type, sharefile) {
        console.log(parameter);
        console.log(type);
        console.log("sharefile",sharefile);
        if (type === "emit") {
          this.$emit(parameter);
        } else {
          if (parameter === "starfile") {
            // this.setPermissionFileStar(sharefile)
            if (sharefile.file_status === "S") {
              this.$emit("callupdatestatusfile", sharefile, "N");
            } else {
              this.$emit("callupdatestatusfile", sharefile, "S");
            }
          } else if (parameter === "trash") {
            this.opendialogconfirmdelete = true;
            // if(sharefile.file_permission === "E"){ 
              // if (sharefile.permission_account.delete === "True") {
  
            // if (sharefile.permission_account === "05") {
            //   console.log("sharefile",sharefile);
            //   this.$emit("callupdatestatusfile", sharefile, "T");
            // } else if (
            //   sharefile.file_owner_eng === "Me" &&
            //   sharefile.file_owner_th === "ฉัน"
            // ) {
              // this.$emit("callupdatestatusfile", sharefile, "T");
            // } else {
            //   Toast.fire({
            //     icon: "error",
            //     title:
            //       "ไม่สามารถลบไฟล์นี้ได้ เนื่องจากไม่ได้รับสิทธิ์จากเจ้าของไฟล์",
            //   });
            // }
          } else if (parameter === "downloadfile") {
            this.downloadfile(sharefile);
          } else if (parameter === "listshare") {
            this.openlistshare = true;
          } else if (parameter === "editname") {
            this.openchangename = true;
          } else if (parameter === "sendmail") {
            this.opensendmail = true;
          } else if (parameter === "movefile") {
            this.opendlmovefile = true;
          } else if (parameter === "sendonechat") {
            this.opendialogonechat = true;
          } else if (parameter === "newpreview") {
            console.log("เข้าpreview");
            this.opennewpreviewfile = true;
          } else if (parameter === "sharefilelink") {
            this.opensharelink = true;
          } else if (parameter === "relationfile") {
            this.openrelationfile = true;
          } else if (parameter === "hide"){
            this.opendialogconfirmhidedirectoryfile = true;
          } else if(parameter === "unhide"){
            this.opendialogconfirmunhidedirectoryfile = true;
          }
          //  else if(parameter === "openfolder"){
          //   if (this.file.file_type === "folder") {
          //       // gotodirectory function
          //       this.gotodirectory(sharefile.file_id, sharefile.file_type, sharefile.file_department, sharefile);
          //     }
          // }
  
          // else if (parameter === "createrelationfile") {
          //   this.opencreaterelationfile = true;
          // }
        }
      },
      // open openfolder แบบคลิกขวา
      gotodirectory(folderid, type, departmentid, file) {
        console.log("folderid", folderid);
        console.log("type", type);
        console.log("departmentid", departmentid);
        console.log("file", file);
        if (file.file_type === "folder") {
          // this.$store.dispatch("department_access_id", file.file_department);
          this.$router.replace({ path: "/directorysharefile/" + file.file_id });
          this.$emit('reload')
        }
        // console.log("_item",file);
        // let permission = JSON.stringify(_item.permission_account);
        
        // if (type === "folder") {
        //   sessionStorage.setItem("permission",permission )
        //   this.$router.replace({ path: "/directorysharefile/" + folderid });
  
        // }
      },
      colsedowload() {
        this.snackbardowload = false;
        this.newlist = [];
        this.listdatadowload = [];
      },
      fn_calculate_size(_sizefilebyte) {
        console.log("ขนาดไฟล์เป็น GB",(_sizefilebyte / 1073741824).toFixed(2));
         return parseFloat((_sizefilebyte / 1073741824).toFixed(2))
        // return gb_sizeDataConverter.convertStorageToKbMbGb(_sizefilebyte);
      },
      async fn_loop_downloading(all_file,zip_name,url_link){
        let total=0;
        
        if(all_file.length === 0){
              this.newlist[this.dataIndex]["value"]=100
                    console.log(url_link);
                     window.location.assign(url_link)
                     this.opensnackbar = false;
            }else{
         for(let i = 0 ; i<all_file.length;i++){
           console.log("all_file.length",(1/all_file.length)*100,total);
               
                total += (1/all_file.length)*100
               let res = await this.downloading_folder(all_file[i]["file_id"],all_file[i]["location_file"],zip_name,all_file[i]["path"]);
                console.log(res);
               if(this.newlist[this.dataIndex]["value"] > 99 ){
                  this.newlist[this.dataIndex]["value"] = 99
                 }else{
                 this.newlist[this.dataIndex]["value"] = Math.round(total);
                }
                this.newpercen = this.newlist[this.dataIndex]["value"];
                this.namefile = this.newlist[this.dataIndex]["name"];
                console.log(this.percentCompleted);
                // if(res.status === "OK" ){
                 
                   
                // }else{
                //   //continue;
                // }
                  console.log(i,(all_file.length)-1);
                 if( i === (all_file.length)-1){
                   this.newlist[this.dataIndex]["value"]=100
                    console.log(url_link);
                     window.location.assign(url_link)
                     this.opensnackbar = false;
          //            this.axios.get(url_link, {})
          //  .then((response) => { 
          //   console.log(response);
          // });
                  }
                  
              
            }
            }
            
      },
      downloading_folder(file_id,location_file,zip_name,all_file){
        let url =
            process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
            "/api/downloading_file?file_id=" +file_id +
            "&location_file=" +location_file+
            "&zip_name=" +zip_name+
            "&path=" +all_file
  
            console.log(url);
            return new Promise((resolve, reject) => {
          this.axios.get(url, {}).then((response) => {
            console.log("testtt",response.data.status);
            // if(response.data.status === "OK"){
             
            setTimeout(() => {
                  resolve({
                    status: response.data.status,
                    msg: "",
                  });
                }, 1);
                  
            // }else{
            //    return new Promise((resolve, reject) => {
            // setTimeout(() => {
            //       resolve({
            //         status: response.data.status,
            //         msg: "",
            //       });
            //     }, 1000);
            //       });
            // }
  
          });
          });
  
          
      },
      async downloadfile(sharefile) {
        // console.log("sharefile",sharefile);
        let checkfilesize = this.fn_calculate_size(sharefile.file_size)
        this.btsdownload = true;
        let datadowload = {};
        console.log(this.listdatadowload);
        let checkdata = this.listdatadowload.findIndex(
          (v) => v.file_id === sharefile.file_id
        );
        // if (checkdata != -1) {
        //   Toast.fire({
        //     icon: "error",
        //     title: "พบการดาวน์โหลดซ้ำ กรุณาดาวน์โหลดใหม่อีกครั้ง",
        //   });
        //   // this.snackbardowload = false;
        //   this.opensnackbar = false;
        //   this.listdatadowload = [];
        //   this.newlist = [];
        // } else {
          this.listdatadowload.push(sharefile);
  
          console.log("this.listdatadowload",this.listdatadowload);
  
          for (let i = 0; i < this.listdatadowload.length; i++) {
            datadowload["fileid"] = this.listdatadowload[i].file_id;
            datadowload["name"] = this.listdatadowload[i].file_name;
            datadowload["value"] = 0;
            console.log(datadowload);
          }
  
          this.newlist.push(datadowload);
          console.log("this.newlist",this.newlist);
          //อันเก่า
          this.percentCompleted = 0;
          if (sharefile.file_type === "folder") {
  
          for (let i = 0; i < this.listdatadowload.length; i++) {
            this.dataIndex = this.newlist.findIndex((obj) => obj.name === this.listdatadowload[i].file_name);
          }
          //this.btsdownload = true;
          this.opensnackbar = true;
          this.new_list = this.newlist;
          let  timeout = 4000
  
          let all_file=[] ;
          let zip_name="" ;
          let url_link ;
            
          
          let url =
            process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
            "/api/request/download_folder?account_id=" +
            this.dataAccountId +
            "&folder_id=" +
            sharefile.file_id;
  
          this.axios.get(url, {})
           .then((response) => { 
          //console.log(response.data.status);
          if(response.data.status === "OK"){
            // all_file = response.data.all_file
            // zip_name =  response.data.zip_name
            // url_link = response.data.url
            //console.log("fff",url_link);
            this.fn_loop_downloading(response.data.all_file,response.data.zip_name,response.data.url);
         
          }else{
                this.btsdownload = false;
                this.opensnackbar = false;
                Toast.fire({
                  icon: "error",
                  title: "ไม่สามารถดาวน์โหลด " + sharefile.file_name + " นี้ได้",
                });
          }
          })
          .catch((error) => {
                this.btsdownload = false;
                this.opensnackbar = false;
                Toast.fire({
                  icon: "error",
                  title: "ไม่สามารถดาวน์โหลด " + sharefile.file_name + " นี้ได้",
                });
                console.log("err", error);
              });
           
          // for (let i = 0; i < this.listdatadowload.length; i++) {
          //   this.dataIndex = this.newlist.findIndex((obj) => obj.fileid === this.listdatadowload[i].file_id);
          // }
          // console.log("folder", this.dataIndex);
  
          // this.opensnackbar = true;
          // this.new_list = this.newlist;
          // let auth = await gbfGenerate.generateToken();
            // this.axios
            // .get(url, {
            //   headers: { Authorization: auth.code },
            //   onDownloadProgress: (progressEvent) => {
            //     // console.log("progressEvent", progressEvent);
            //     let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  
            //     if (this.newlist[this.dataIndex]["value"] === 95) {
            //       this.newlist[this.dataIndex]["value"] = 95;
            //     } else {
            //       this.newlist[this.dataIndex]["value"] = progresspercent;
            //     }
            //     // console.log("percenfolder", this.newlist[this.dataIndex]["value"]);
            //     this.newpercen = this.newlist[this.dataIndex]["value"];
            //     this.namefile = this.newlist[this.dataIndex]["name"];
            //   },
            //   withCredentials: false,
            //   responseType: "arraybuffer",
            // })
            // .then(response => {
            //   console.log("reponse folder",response);
            //   // ตรวจสอบว่า  API ทำงานถูกต้อง
            //   if (response.statusText === "OK") {
            //     // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
            //     if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
            //        // Backend ไม่ส่งไฟล์มา
            //       this.opensnackbar = false;
            //       let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
            //       Toast.fire({ icon: "error", title: res_error.errorMessage });
            //       } else {
            //         // Backend ส่งไฟล์มา
            //         this.newlist[this.dataIndex]["value"] = 100;
            //         this.btsdownload = false;
            //         let headers = response.headers;
            //         let blob = new Blob([response.data],{type:headers['content-type']});
            //         let link = document.createElement("a");
            //         link.href = window.URL.createObjectURL(blob);
            //         link.download = currentfile.file_name;
            //         link.click();
            //         setTimeout(() => {
            //           this.opensnackbar = false;
            //         }, 2500)
            //       }
            //   }else{
            //      this.opensnackbar = false;
            //   }
            //   this.listdatadowload = []; //เคลียร์listที่เลือกดาวน์โหลด
            //   this.newlist = []; //เคลียร์ array เป็น key แล้ว
            //   this.newpercen = []; //เคลียร์ค่าเปอร์เซ็น
            //   // console.log("ดาวน์โหลดเสร็จ",this.newlist);
            //   // console.log("ดาวน์โหลดเสร็จpercen",this.newpercen);
            // }).catch(error => {
            //   this.btsdownload = false;
            //   this.listdatadowload = []; //เคลียร์listที่เลือกดาวน์โหลด
            //   this.newlist = []; //เคลียร์ array เป็น key แล้ว
            //   this.newpercen = []; //เคลียร์ค่าเปอร์เซ็น
            //   Toast.fire({
            //     icon: "error",
            //     title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
            //   });
            //   console.log(error);
            // });
        } else {
            let payload = {
              account_id: this.dataAccountId,
              user_id: this.dataUsername,
              file_id: sharefile.file_id,
            };
  
            let url =
              process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
              "/api/v2/download_file?user_id=" +
              this.dataUsername +
              "&file_id=" +
              sharefile.file_id;
  
            // this.snackbardowload = true;
  
            for (let i = 0; i < this.listdatadowload.length; i++) {
              this.dataIndex = this.newlist.findIndex(
                (obj) => obj.fileid === this.listdatadowload[i].file_id
              );
            }
            console.log("folder", this.dataIndex);
            if(checkfilesize < 1.00){
              // console.log("น้อยกว่า 1 GB");
              this.opensnackbar = true;
              this.new_list = this.newlist;
              let auth = await gbfGenerate.generateToken();
              this.axios
              .get(url, {
                onDownloadProgress: (progressEvent) => {
                  let progresspercent = parseInt(
                    Math.round((progressEvent.loaded / progressEvent.total) * 100)
                  );
                  console.log(progresspercent);
                  this.namedowload = sharefile.file_name;
                  if (this.newlist[this.dataIndex]["value"] === 95) {
                    this.newlist[this.dataIndex]["value"] = 95;
                  } else {
                    this.newlist[this.dataIndex]["value"] = progresspercent;
                  }
                  this.newpercen = this.newlist[this.dataIndex]["value"];
                  this.namefile = this.newlist[this.dataIndex]["name"];
                  console.log("this.namefile",this.namefile);
                },
                withCredentials: false,
                responseType: "arraybuffer",
                 headers: { Authorization: auth.code },
              })
              .then((response) => {
                if (response.statusText === "OK") {
                  if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
                    this.opensnackbar = false;
                    let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                    Toast.fire({ icon: "error", title: res_error.errorMessage });
                  } else {
                    this.newlist[this.dataIndex]["value"] = 100;
                    this.btsdownload = false;
                    let headers = response.headers;
                    let blob = new Blob([response.data],{type:headers['content-type']});
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = sharefile.file_name;
                    link.click();
                    // const blob = new Blob([response.data]);
                    // const content = response.headers["content-type"];
                    // saveAs(blob, sharefile.file_name);
                    setTimeout(() => {
                      this.opensnackbar = false
                      }, 2500);
                  }
                }else{
                  this.opensnackbar = false
                }
                this.listdatadowload = []; //เคลียร์listที่เลือกดาวน์โหลด
                this.newlist = []; //เคลียร์ array เป็น key แล้ว
                this.newpercen = []; //เคลียร์ค่าเปอร์เซ็น
              })
              .catch((error) => {
                this.btsdownload = false;
                Toast.fire({
                  icon: "error",
                  title: "ไม่สามารถดาวน์โหลด " + sharefile.file_name + " นี้ได้",
                });
                console.log(error);
              });
            }else{
              this.opensnackbardownloadfile = true;
              this.btsdownload = true;
              let  timeout = (checkfilesize*4000)
              window.location.assign(url)
              setTimeout(() => {
              this.opensnackbardownloadfile = false;
            }, timeout)
            }
          }
        // }
      },
      // async download(sharefile) {
      //   let response = await this.axios.get(
      //     process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
      //       "/api/v2/download_file?user_id=" +
      //       this.dataUsername +
      //       "&file_id=" +
      //       sharefile.file_id,
      //     { responseType: "arraybuffer" }
      //   );
      //   let blob = new Blob([response.data], {
      //     type: response.headers.get("content-type")
      //   });
      //   let link = document.createElement("a");
      //   link.href = window.URL.createObjectURL(blob);
      //   link.download = sharefile.file_name;
      //   link.click();
      // },
      closeDialogEditname() {
        this.$emit("closecurrent");
      },
    },
  };
  </script>
  
  <style>
  #app_currentrightclick {
    font-family: "Sarabun", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    background-color: white;
  }
  
  .v-progress-circular {
    margin: 1rem;
  }
  </style>